@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Amiri&family=Tajawal&display=swap');

*:not(.verse){
    font-family: 'Tajawal', sans-serif;
}
.verse{
    font-family: 'Amiri', serif;
}

audio::-webkit-media-controls-panel {
  @apply bg-neutral-100 text-xl;
}
audio::-moz-media-controls-panel {
    @apply bg-neutral-100;
}
audio::-ms-media-controls-panel {
    @apply bg-neutral-100;
}
audio::-webkit-media-controls-timeline-container{
    @apply text-xl;
}
audio::-webkit-media-controls-current-time-display{
    @apply text-xl;
}
audio::-webkit-media-controls-time-remaining-display{
    @apply text-xl;
}

html.dark audio::-webkit-media-controls-panel{
    @apply bg-neutral-600;
}

html.dark audio::-webkit-media-controls-panel{
    @apply text-white text-xl;
}
html.dark audio::-webkit-media-controls-mute-button{
    @apply text-white text-xl;
}
html.dark audio::-webkit-media-controls-play-button{
    @apply text-white text-xl bg-neutral-50 rounded-full;
}
html.dark audio::-webkit-media-controls-timeline-container{
    @apply text-white;
    text-shadow: none !important;
}
html.dark audio::-webkit-media-controls-current-time-display{
    @apply text-white text-xl;
}
html.dark audio::-webkit-media-controls-time-remaining-display{
    @apply text-white text-xl;
}
html.dark audio::-webkit-media-controls-timeline{
    @apply text-white text-xl;
}
html.dark audio::-webkit-media-controls-volume-slider-container{
    @apply text-white text-xl;
}
html.dark audio::-webkit-media-controls-volume-slider{
    @apply text-white text-xl bg-neutral-50 rounded-full px-4;
}
html.dark audio::-webkit-media-controls-seek-back-button{
    @apply text-white text-xl;
}
html.dark audio::-webkit-media-controls-seek-forward-button{
    @apply text-white text-xl;
}
html.dark audio::-webkit-media-controls-fullscreen-button{
    @apply text-white text-xl;
}
html.dark audio::-webkit-media-controls-rewind-button{
    @apply text-white text-xl;
}
html.dark audio::-webkit-media-controls-return-to-realtime-button{
    @apply text-white text-xl;
}
html.dark audio::-webkit-media-controls-toggle-closed-captions-button{
    @apply text-white text-xl;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  @apply bg-emerald-600;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    @apply bg-emerald-400;
}